import React from 'react';
import './Footer.css';

export const Footer = () => {
  return (
    <div className='contenedorFooter'>
        <h3>@ 2022 Who? Consultora Integral | Todos los derechos reservados.</h3>
    </div>
  )
}
