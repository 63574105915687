import React from 'react';
import './style.css';

const Header =()=>{
    return(
        <div className='contenedorHeader'>
            <h3>CONSEGUIMOS EL TALENTO QUE NECESITA TU EMPRESA.</h3>
        </div>
    )
}

export default Header;