import React from "react";
import { Footer } from "../utils/footer/Footer";
import NavBar from "../utils/NavBar";
import Seccion from "./seccion/Seccion";


const Historia =()=>{
    return(
        <>
            <NavBar/>
            <Seccion />
            <Footer />
        </>
    )
}

export default Historia